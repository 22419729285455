import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import AlertCard from "./components/AlertCard";
import PaginationBar from "../../../components/PaginationBar";

const amountPerPage = 30;

function InstallationDetailAlerts({ installation }) {
    const [alerts, setAlerts] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState("open");
    const [page, setPage] = useState(1);

    const refreshAlerts = useCallback(async (page, clear = true) => {
        if(!installation) {
            return;
        }
        if(clear) {
            setAlerts(null);
            setPage(1);
            setTotalAmount(null);
        }
        setError(null);
        try {
            const response = await axios.post("/getInstallationAlerts", {
                installationId: installation.id,
                filter,
                limit: amountPerPage,
                offset: (page - 1) * amountPerPage,
            });
            setAlerts(response.data.alerts);
            setTotalAmount(response.data.totalAmount);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden bij het ophalen van de alerts.");
        }
    }, [installation, filter]);

    const emptyStateTitle = useMemo(() => {
        switch(filter) {
            case "open":
                return "Geen open alerts";
            case "closed":
                return "Geen gesloten alerts";
            default:
                return "Geen alerts";
        }
    }, [filter]);
    const emptyStateMessage = useMemo(() => {
        switch(filter) {
            case "open":
                return "Er zijn geen open alerts vanuit apparaten in deze installatie.";
            case "closed":
                return "Er zijn geen gesloten alerts vanuit apparaten in deze installatie.";
            default:
                return "Er zijn geen alerts vanuit apparaten in deze installatie.";
        }
    }, [filter]);

    const pageCount = useMemo(() => {
        if(!totalAmount) {
            return null;
        }
        return Math.ceil(totalAmount / amountPerPage);
    }, [totalAmount]);

    const goToPage = useCallback(async (page) => {
        setPage(page);
        setAlerts(null);
        await refreshAlerts(page, false);
    }, [refreshAlerts]);

    useEffect(() => {
        refreshAlerts();
    }, [refreshAlerts]);

    return (
        <React.Fragment>
            <div>
                <div className="btn-group mb-3">
                    <Button
                        variant={ filter === "open" ? "primary" : " page-link" }
                        onClick={ () => setFilter("open") }
                    >
                        Actief
                    </Button>
                    <Button
                        variant={ filter === "closed" ? "primary" : " page-link" }
                        onClick={ () => setFilter("closed") }
                    >
                        Gesloten
                    </Button>
                    <Button
                        variant={ filter === "all" ? "primary" : " page-link" }
                        onClick={ () => setFilter("all") }
                    >
                        Alle
                    </Button>
                </div>
            </div>

            { pageCount > 1 && (
                <div className="mb-3">
                    <PaginationBar
                        page={ page }
                        pagesAmount={ pageCount }
                        onPageChange={ goToPage }
                    />
                </div>
            )}

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !alerts ? (
                <Loading/>
            ) : alerts.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fa-solid fa-sun fa-spin-slow"/></h1>
                    <h4>{ emptyStateTitle }</h4>
                    <p>{ emptyStateMessage }</p>
                </div>
            ) : (
                alerts.map((alert) => (
                    <AlertCard
                        key={ alert.id }
                        alert={ alert }
                    />
                ))
            )}

            { alerts && pageCount > 1 && (
                <div>
                    <PaginationBar
                        page={ page }
                        pagesAmount={ pageCount }
                        onPageChange={ goToPage }
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(InstallationDetailAlerts);
