import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import DateFormatter from "../../../../components/formatters/DateFormatter";

function AlertStatus({ alert }) {
    return (
        <OverlayTrigger overlay={(props) => (
            <Tooltip {...props}>
                { alert.open ? "Actieve alert" : "Gesloten alert" }
            </Tooltip>
        )}>
            { alert.open ? (
                <i className="fa-solid fa-circle-exclamation text-danger"/>
            ) : (
                <i className="fa-solid fa-circle-check text-success"/>
            )}
        </OverlayTrigger>
    )
}

function AlertCard({ alert }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex flex-row">
                    { alert.inverter && (
                        <h5 className="card-title flex-grow-1">
                            { alert.inverter.manufacturer }{" "}
                            <br className="d-md-none"/>
                            { alert.inverter.model }
                            <div className="text-muted mt-1" style={{ fontSize: "0.85rem" }}>
                                { alert.inverter.serialNumber }
                            </div>
                        </h5>
                    )}
                    <div>
                        <AlertStatus alert={ alert }/>
                    </div>
                </div>
                <p className="card-text">
                    { alert.message }
                </p>
                <div className="d-flex flex-column flex-lg-row" style={{ gap: "15px" }}>
                    <div className="text-muted">
                        Eerste constatering:{" "}
                        { alert.startDate ? (
                            <DateFormatter date={ alert.startDate }/>
                        ) : "Onbekend" }
                    </div>
                    <div className="text-muted">
                        Laatst gezien:{" "}
                        { alert.lastSeenDate ? (
                            <DateFormatter date={ alert.lastSeenDate }/>
                        ) : "Onbekend" }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AlertCard);
